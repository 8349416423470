.clearfix {
  @include clearfix();
}
.top-wrap {
  @include clearfix();

  max-height: 1000px !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 720px;

  @include media($large-screen) {
    min-height: 530px;
  }
}

.hero-wrap {
  @include clearfix;
  box-shadow: black 0 2px 7px;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-top: solid 1px $alt-colour;
  border-bottom: solid 1px $alt-colour;


}

.slidecontainer {
  @include clearfix;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cycle-pager {
  overflow: hidden;
  //position: absolute;
  text-align: left;
  padding-left: 30px;
  bottom: 0;
  width: 100%;
  z-index: 999;

  @include media($full-screen) {
    width: $max-inner-width;
    display: block;
    margin: auto;
  }

    span {
    color: $main-colour;
    cursor: pointer;
    display: inline-block;
    font-size: 50px;
    height: 16px;
    width: 16px;
  }

  .cycle-pager-active {
    color: $alt-colour;
  }

  > * { cursor: pointer;}
}

.cycle-prev,
.cycle-next {
  position: absolute;
  top: 0; width: 20%;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 999;
  height: 100%;
  cursor: pointer;

}

.cycle-prev {
  left: 0;
  background: url(/images/left.png) 5% 50% no-repeat;
}
.cycle-next {
  right: 0;
  background: url(/images/right.png) 95% 50% no-repeat;
}
.cycle-prev:hover,
.cycle-next:hover {
  opacity: .7;
  filter: alpha(opacity=70)
}

.disabled {
  opacity: .5;
  filter:alpha(opacity=50);
}

.hero {
  $hero-background-top: #7F99BE;
  $hero-background-bottom: #20392B;
  $hero-color: white;
  $gradient-angle: 10deg;
  $hero-image1: '/images/banner1.jpg';
  $hero-image2: '/images/banner2.jpg';
  $hero-image3: '/images/banner3.jpg';
  $hero-image4: '/images/banner4.jpg';
  $hero-image5: '';
  /*
  $hero-image6: 'http://lorempixel.com/1000/400/nature/1';
  */
  flex-grow: 1;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 270px;

  @include media($full-screen) {
    min-height: 400px;
  }

  .hero-img1 {
    background: url($hero-image1);
    background-position: center right;
    justify-content: center;
    align-items: center;

    @media screen and (min-height: 460px) and (min-width: $large-screen) {
      justify-content: flex-start;
      align-items: center;
      padding: 2% 2%;
    }
  }

  .hero-img2 {
    background: url($hero-image2);
    background-position: center;
    justify-content: center;
    align-items: center;

    @media screen and (min-height: 460px) and (min-width: $large-screen) {
      justify-content: flex-end;
      align-items: flex-start;
      padding: 5% 2% 2%;
    }
  }

  .hero-img3 {
    background: url($hero-image3);
    background-position: center;
    justify-content: center;
    align-items: center;

    @media screen and (min-height: 460px) and (min-width: $large-screen) {
      justify-content: center;
      align-items: flex-end;
      padding: 0 10% 5%;
    }
  }

  .hero-img4 {
    background: url($hero-image4);
    background-position: center;
    justify-content: center;
    align-items: center;

    @media screen and (min-height: 460px) and (min-width: $large-screen) {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 5% 10% 5%;
    }
  }

  .hero-img1,
  .hero-img2,
  .hero-img3,
  .hero-img4,
  .hero-img5 {
    @include clearfix;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    display: flex;
  }

  .hero-inner {
    color: #ffffff;
    text-align: center;
    max-width: 90%;
    display: flex;

    @media screen and (min-height: 460px) and (min-width: $large-screen) {
      max-width: 700px;
    }

    .hero-copy {
      text-shadow: 3px 3px 10px $alt-colour,  3px -3px 10px $alt-colour, -3px -3px 10px $alt-colour, -3px 3px 10px $alt-colour,;
      background-color: rgba(0,0,0,.2);
      display: inline-block;
      padding: 10px 15px 5px;
      border: solid 1px $border-color;
      border-radius: 2px;

      h2 {
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        font-size: 1.7em;
        margin-bottom: .1em;

        @media screen and (min-height: 460px) and (min-width: $large-screen) {
          font-size: 2.4em;
        }

        @media screen and (min-height: 460px) and (min-width: $full-screen) {
          font-size: 3em;
        }
      }

      p {
        font-weight: 400;
        line-height: 1.1em;

        @media screen and (min-height: 460px) and (min-width: $large-screen) {
          font-size: 1.4em;
        }
      }

    }
  }

  /*
  .hero-img5 {
    background-position: top;

    .hero-copy {
      background: none;

      p {
        text-shadow: 1px 1px 1px rgba(255,255,255,1);
        color: #000;

        &.offerterms {
          color: $alt-colour;
          font-size: 1.3em;
          text-shadow: 1px 1px 1px rgba(0,0,0,1);

          @include media($medium-screen) {
            font-size: 1.5em;
          }

          @include media($large-screen) {
            font-size: 2em;
          }
        }
      }
    }
  }
  */

  .heronav {
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 100%;
    z-index: 9999999;

    span {
      color: #ddd;
      cursor: pointer;
      display: inline-block;
      font-size: 50px;
      height: 16px;
      width: 16px;

      .cycle-pager-active {
        color: #D69746;
      }
    }

    > * { cursor: pointer;}
  }
}

.lower-hero {
  background-image: url("/images/bg-glass.png");
  background-position: center;
  background-size: cover;
  background-color: $main-colour;
  padding: 20px 10px;
  border-top: solid 1px $alt-colour;

  .comp-name {
    font-size: 1.2em;
  }

  p {
    margin: 0;
    font-weight: 700;
  }

  @include media($large-screen) {
    font-size: 1.3em;
  }
}

.cta-button {
  @include transition(all 0.3s ease-in-out);
  @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
          radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
  @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s );

  cursor: pointer;
  border: solid $border-size $border-color;
  border-radius: 5px;
  color: $navigation-color-hover;
  background-color: $navigation-background-hover;
  font-weight: 400;
  margin: 5px 0;
  padding: 10px 25px;
  display: inline-block;
  line-height: 1;
  text-shadow: 1px 1px 2px $shadow-colour;
  box-shadow: black 2px 2px 5px;

  &:hover {
    font-weight: 400;
    color: darken($navigation-color-hover, 10%);
  }
}
