.sidemenu {
  @include border-radius(10px);
  @include border-color(black);
  @include border-width(2px);
  @include border-style(solid);

  box-shadow: black 2px 2px 7px;
  background-color: $main-colour;
  max-width: 400px;
  margin: 10px auto;
  font-size: 1.2em;

  @include media ($large-screen) {
    margin: 20px 0;
  }


  li {
    background: $navigation-background;
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 0;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: black;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: black;

    &:first-child {
      @include border-top-radius(10px);
      border-top: none;
    }

    &:last-child {
      @include border-bottom-radius(10px);
      border-bottom: none;
    }


    a {
      @include transition(all 0.3s ease-in-out);
      display: block;
      color: $navigation-color;
      font-weight: 500;
      text-shadow: 1px 1px 1px #333333;

      @include media ($large-screen) {
        padding: 10px 5px;
      }

      &.navOn,
      &:focus,
      &:hover {
        color: $main-colour;
        background-color: $navigation-background-hover;
      }
    }
    &:first-child {
      a {
        &.navOn,
        &:focus,
        &:hover {
          @include border-top-radius(9px);
        }
      }
    }

    &:last-child {
      a {
        &.navOn,
        &:focus,
        &:hover {
          @include border-bottom-radius(9px);
        }
      }
    }

  }
}
