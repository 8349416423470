.video {
  margin: 10px auto;

  @include media ($large-screen) {
    float: right;
    margin: 10px 0 10px 10px;
    max-width: 45%;
  }

  .video-border {
    @include clearfix;
    border-radius: 2px;
    box-shadow: black 2px 2px 7px;
  }

  .video-wrapper {
    border-radius: 2px;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%; // For ratio 16:9. 75% if ratio is 4:3
    position: relative;

    embed,
    object,
    iframe {
      @include position(absolute, 0px null null 0px);
      @include size(100%);
    }
  }
}
