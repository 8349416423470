.services {
  article {
    h3 {
      font-weight: 400;
      color: $alt-colour;
      margin-top: 10px;
      margin-bottom: 5px;
      text-shadow: 1px 1px 1px #000;
    }
  }
  ul {
    font-size: 1.2em;
    display: block;
    list-style-type: disc;
    padding-left: 40px;
    margin-bottom: 15px;
  }
}