.showroom {
  .pagination {
    @include clearfix;

    a {
      color: $main-colour;
      font-weight: 700;

      &:hover {
        color: darken($main-colour, 10%)
      }
    }

    div {
      display: inline-block;
      padding: 0 2px;
      vertical-align: middle;
      line-height: 1.1;
    }

    span {
      display: block;
    }

    @include media ($medium-screen) {
      span {
        display: inline-block;
      }
    }
  }

  .pagination,
  .jumpperpage,
  .jump-order,
  .jumpgoto {
    text-align: center;

    @include media ($large-screen) {
      @include span-columns(6);
      @include omega(2n);
    }

    label,
    select {
      display: inline-block;
      margin: 3px 0;
    }
  }

  .bottom-pagination {
    .pagination {
      @include span-columns(12);
      @include omega(1);
    }
  }

  .item {
    margin: 10px 0;
    border-radius: $base-border-radius;
    box-shadow: black 2px 2px 7px;
  }

  .itembody {
    @include clearfix;
    @include border-bottom-radius($base-border-radius);
    @include border-color(darken($navigation-background, 5%));
    @include border-width($border-size);
    @include border-style(solid);

    border-top: none;
    padding: 5px;

    @include media ($large-screen) {
      p {
        font-size: 1.2em;
      }
    }
  }

  .piccont,
  .specs {
    @include media ($medium-screen) {
      @include span-columns(6);
      @include omega(2n);
    }
  }

  .itemhead {
    @include border-top-radius($base-border-radius);
    @include background-image(linear-gradient(hsla(0, 100%, 100%, .1) 0%, hsla(0, 100%, 100%, .3) 100%, transparent 2%),
            radial-gradient(darken($navigation-background, 5%), darken($navigation-background, 15%)));

    background-color: $navigation-background;
    a {

      &:hover {
        color: lighten($alt-colour, 30%);
        text-shadow: 1px 1px 3px rgba(0,0,0,.80);
      }
    }


    h2 {
      margin: 0;
      padding: 5px;
      line-height: 1;
    }
  }

  .itemname {
    text-align: center;
  }

  .itemprice {
    text-align: center;
  }

  .piccont {
    text-align: center;
  }

  .mainpic {
    width: 50%;
    position: relative;
    margin: auto;

    .mainimg {
      width: 100%;
    }
  }

  .specialoffer {
    img {
      position: absolute;
      border-radius: $base-border-radius 0 0 0;
      border: none;
      box-shadow: none;
      top: 0;
      left: 0;
      width: 35%
    }
  }

  .thumbnails {
    img {
      display: inline-block;
      height: 70px;
      margin: 3px;
    }
  }

  .specscol {
    @include span-columns(6);
    @include omega(2n);
  }
}

