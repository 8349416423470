body {
  background-color: $content-background-color;

  .main {
    @include clearfix();
    min-height: 100vh;
    overflow: hidden;
  }

  .holding {
    h2 {
      text-align: center;
      margin: 30px 0;
    }
  }
}

.tags {
  text-align: center;
  display: none;
}

