$navigation-submenu-padding: 1em;
$navigation-submenu-width: 17em;

.nav-wrap {
  @include clearfix();
  @include box-sizing(border-box);

  margin-top: 0;
  min-height: $navigation-height;

  .navigation {
    font-size: 1.2em;
    text-align: center;
    z-index: 999;

    a {
      text-decoration: none;
    }

    .navigation-wrapper {
      @include clearfix;
      max-width: $max-inner-width;
      margin: auto;
      position: relative;
      z-index: 9999;
    }

    // Mobile view

    .navigation-menu-button {
      color: $navigation-color;
      display: block;
      float: right;
      font-weight: 700;
      line-height: $navigation-height;
      margin: 0;
      padding-right: 1em;
      text-transform: uppercase;

      @include media ($large-screen) {
        display: none;
      }

      &:focus,
      &:hover {
        color: $navigation-active-link-color;
      }
    }

    // Nav menu

    nav {
      float: none;
      min-height: $navigation-height;
      z-index: 9999999;
    }

    ul.navigation-menu {
      -webkit-transform-style: preserve-3d; // stop webkit flicker
      clear: both;
      display: none;
      margin: 0 auto;
      overflow: visible;
      padding: 0;
      width: 100%;
      z-index: 9999;
      position: relative;

      &.show {
        display: block;
      }

      @include media ($large-screen) {
        display: inline;
        margin: 0;
        padding: 0;
      }
    }

    // The nav items

    ul li.nav-link {
      background: $navigation-background;
      display: block;
      line-height: $navigation-height;
      overflow: hidden;
      text-align: right;
      padding-right: 20px;
      width: 100%;
      z-index: 9999;

      @include media ($large-screen) {
        background: transparent;
        display: inline;
        line-height: $navigation-height;
        text-align: center;
        text-decoration: none;
        padding: 0;
        border-right: dashed 1px $border-color;

        &:last-child {
          border-right: none;
        }
      }

      a {
        @include transition(all 0.3s ease-in-out);

        color: $navigation-color;
        display: inline-block;
        font-weight: 400;

        @include media ($large-screen) {
          margin: 0;
          padding: 0;
          width: 19%;
        }

        &.active-nav-item,
        &:focus,
        &:hover {
          color: $navigation-active-link-color;
        }

      }
      &:first-child {
        a {
          &.active-nav-item,
          &:focus,
          &:hover,
          &:before {
            @include border-left-radius(7px);
          }
        }
      }

      &:last-child {
        a {
          &.active-nav-item,
          &:focus,
          &:hover,
          &:before {
            @include border-right-radius(7px);
          }
        }
      }
    }

    ul li.nav-link:last-child {
      @include media ($large-screen) {
      }
    }

    // Sub menus

    li.more.nav-link {
      padding-right: 0;

      a {
        @include transition(all 0.3s ease-in-out);

        margin-right: $navigation-submenu-padding;
      }

      > a {
        padding-right: 0.6em;
      }

      > a:after {
        @include transition(all 0.3s ease-in-out);
        @include position(absolute, auto -0.4em auto auto);
        color: $navigation-color;
        content: "\25BE";
      }

      > a {
        &.active-nav-item,
        &:focus,
        &:hover {

          &:after {
            color: $navigation-active-link-color;
          }
        }
      }

      @include media($large-screen) {

        a {
          margin-right: 0;
        }

        > a {
          padding-right: 0;
        }

        > a:after {
          @include position(absolute, auto .2em auto auto);
          color: $navigation-color;
          content: "\25BE";
        }

        > a {
          &.active-nav-item,
          &:focus,
          &:hover {

            &:after {
              color: $navigation-active-link-color;
            }
          }
        }
      }
    }

    li.more {

      overflow: visible;
      padding-right: 0;

      a {
        padding-right: $navigation-submenu-padding;
      }

      > a {
        padding-right: 1.6em;
        position: relative;

        @include media($large-screen) {
          margin-right: $navigation-submenu-padding;
        }

        &:after {
          content: "›";
          font-size: 1.2em;
          position: absolute;
          right: $navigation-submenu-padding / 2;
        }
      }

      &:focus > .submenu,
      &:hover > .submenu {
        max-height: 2000px;

        @include media($large-screen) {
          border-color: $border-color;
        }
      }

      @include media($large-screen) {
        padding-right: $navigation-submenu-padding;
        position: relative;
      }
    }

    ul.submenu {
      @include transition(all 0.5s ease-in-out);

      max-height: 0;
      padding-left: 0;

      @include media($large-screen) {
        border: solid 1px transparent;
        border-radius: $border-radius;
        overflow: hidden;
        right: -$navigation-submenu-padding;
        position: absolute;
        top: 1.5em;
      }

      .submenu {
        @include media($large-screen) {
          left: 10em;
          top: 0;
        }
      }

      li {
        display: block;
        padding-right: 0;
        line-height: 1.2;
        border-bottom: solid 1px lighten($navigation-background, 5%);

        @include media($large-screen) {
          font-size: 0.8em;
          &:first-child > a {
            @include border-top-radius(7px);

            &.active-nav-item,
            &:focus,
            &:hover,
            &:before {
              @include border-top-radius(7px);
            }
          }

          &:last-child > a {
            @include border-bottom-radius(7px);
            border-bottom: none;

            &.active-nav-item,
            &:focus,
            &:hover,
            &:before {
              @include border-bottom-radius(7px);
            }
          }
        }

        a {
          background-color: darken($navigation-background, 5%);
          display: inline-block;
          text-align: right;
          text-decoration: none;
          width: 100%;
          padding: 10px;
          margin: 0 !important;

          @include media($large-screen) {
            background-color: $navigation-background;
            padding: 10px;
            text-align: left;
            width: $navigation-submenu-width;
          }
        }
      }
    }
  }
}
