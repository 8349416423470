.openhours {
  max-width: 450px;
  margin: 0 auto 10px;

  p {
    padding: 5px;
    margin: 0;
  }

  .openrow {
    &:nth-child(odd) {
      background-color: lighten($main-colour, 20%);
    }

  }

  .day {
    display: inline-block;
    width: 40%;
  }

  .time {
    display: inline-block;
    width: 55%;
  }

  .callout {
    text-align: center;
    p {
      color: $alt-colour;
      text-shadow: 1px 1px 1px #000;
      margin: 0;
      line-height: 1;

      strong {
        font-weight: 900;
        font-size: 1.1em;
      }
    }
  }
}
