body {
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

@for $i from 1 through 6 {
  h#{$i} {
    font-size: modular-scale((6 - $i), 1em, 1.2);
  }
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color 0.1s linear;

  &:active,
  &:focus,
  &:hover {
    color: darken($action-color, 15%);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}
