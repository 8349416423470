.contentwrap {
  max-width: $max-inner-width;
  margin: auto;
}

.content,
.boxes {
  @include clearfix;
  font-size: .8em;
  font-weight: 400;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .50);

  p {
    font-size: 1em;
    margin-bottom: 0.5em;
    &.callout {
      text-align: center;
      font-weight: 400;
      color: $alt-colour;
      text-shadow: 1px 1px 1px #000;
    }
  }

  h2 {
    font-weight: 400;
    color: $alt-colour;
    font-size: 2.4em;
    margin-top: 10px;
    margin-bottom: 5px;
    text-shadow: 1px 1px 1px #000;
  }

  h3,
  h4 {
    font-weight: 700;
    margin: 0;
  }

  h6 {
    line-height: 1.4;
    font-size: 1.3em;
    font-weight: 400;
  }

  .note {
    font-size: .7em;
    font-weight: normal;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .50);
  }

  a {
    @include transition(all 0.3s ease-in-out);
    font-weight: 400;
    color: $alt-colour;

    &:hover {
      color: darken($alt-colour, 15%);
      font-weight: 400;
    }
  }

  .indent {
    margin-left: 20px;
  }

  @include media ($large-screen) {

    h6 {
      font-size: 1.4em;
    }

    p {
      font-size: 1.2em;
    }
  }

  @include media ($full-screen) {
    h6 {
      font-size: 1.6em;
    }

    p {
      font-size: 1.3em;
    }
  }
}

.content {
  padding: 0;
  margin: 0 10px;

  .main-col {
    h3 {
      font-weight: 400;
      color: $alt-colour;
      margin-top: 10px;
      margin-bottom: 5px;
      text-shadow: 1px 1px 1px #000;
    }

    @include media ($large-screen) {
      @include span-columns(9);
    }
  }

  .fb-wrapper {
    @include clearfix;
    margin: 20px auto 10px;
    max-width: 500px;

    iframe {
      border-radius: 3px;
      box-shadow: black 2px 2px 7px;
    }
  }

  .aside {
    .payments {
      p {
        text-align: center;
        font-size: 1.2em;
        font-weight: 400;
      }
      img {
        border: none;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
      }
    }

    @include media ($large-screen) {
      @include span-columns(3);

      border: none;
      border-radius: 2px;
      padding: 0;

      .openhours {
        p {
          font-size: 1em;
        }
      }

      .payments {
        p {
          font-size: 1em;
        }
      }
    }

    @include media ($full-screen) {
      @include span-columns(3);
      .openhours {
        p {
          font-size: 1.3em;
        }
      }
    }
  }

  aside {
    text-align: center;

    h2 {
      text-align: left;
    }

    @include media ($large-screen) {
      text-align: left;
    }
  }

  .contact-details {
    max-width: 450px;
    margin: auto;

    h3 {
      font-size: 1.7em;

      .nowrap-numbers {
        display: inline-block;
        color: #333333;
        text-shadow: inherit;
      }
    }
  }

  img {
    border: solid 1px $alt-colour;
    border-radius: 10px;
    box-shadow: black 2px 2px 7px;
    display: block;
    height: auto;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding: 3px;

    &.fright {
      margin: 10px auto;

      @include media ($large-screen) {
        float: right;
        margin: 10px 0 10px 10px;
        max-width: 30%;
      }
    }

    &.info-fright {
      margin: 10px auto;

      @include media ($medium-screen) {
        float: right;
        margin: 10px 0 10px 10px;
        max-width: 45%;
      }
    }

    &.about-fright {
      margin: 10px auto;

      @include media ($medium-screen) {
        float: right;
        margin: 10px 0 10px 10px;
        width: 30%;
        max-width: 300px;
      }
    }

    &.fleft {
      margin: 10px auto;

      @include media ($large-screen) {
        float: left;
        margin: 10px 10px 10px 0;
        max-width: 35%;
      }
    }

    &.about-fleft {
      margin: 10px auto;

      @include media ($medium-screen) {
        float: left;
        margin: 10px 10px 10px 0;
        width: 35%;
        max-width: 400px;
      }
    }

    &.divider {
      border: 0;
      box-shadow: none;
      padding: 0;
      margin: 0 auto 25px;
      width: 85%;
    }
  }
}

.radio {
  text-align: center;

  h2,
  audio {
    vertical-align: middle;
    display: inline-block;
    max-width: 100%;
  }

  h3 {
    font-size: 2.2em;
    font-weight: 400;

    @include media ($medium-screen) {
      font-size: 3em;
    }
  }
}

.maintxt {
  span,
  a {
    @include transition(all 0.3s ease-in-out);
    font-weight: 400;
    color: $alt-colour;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);

    &:hover {
      color: lighten($alt-colour, 15%);
    }
  }
}

.about {
  font-size: 1.1em;

  .lead {
    font-size: 1.4em;
  }
}

.comp-name {
  color: $dark-logo-colour;
  font-weight: 700 !important;
  font-style: normal;
  font-stretch: expanded;
}
