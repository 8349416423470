.maps {

  #map_canvas {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .50);
    border-top: solid 1px $border-color;
    width: 100%;
    height: 400px;
  }

  #map_canvas img {
    max-width: none; // to prevent UI control bug
    border-radius: 0;
    box-shadow: none;
  }

  #map_canvas .content {
    padding: .7em .3em 1em .3em;
  }

  #map_canvas .firstHeading {
    font-size: 1.8em;
    margin-bottom: 0;
  }

  #map_canvas .bodyContent p {
    font-size: 1em;
    line-height: 1.3;
    margin-bottom: 0;
  }

  #map_canvas .bodyContent p:last-of-type {
    border-top: 1px solid silver;
    margin-top: .5em;
    padding-top: .3em;
  }

}

