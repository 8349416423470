.boxes {
  @include clearfix;
  background-color: lighten($main-colour, 25%);
  //border-top: solid 1px $alt-colour;
  //border-bottom: solid 1px $alt-colour;
  padding: 10px 10px 20px;

  .infobox {
    margin-top: 10px;
    //border: solid 1px $alt-colour;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: black 1px 1px 7px;

    @include media (min-width $small-screen max-width $large-screen) {
      @include span-columns(6);
      @include omega(2n);
    }

    @include media ($large-screen) {
      @include span-columns(3);
      @include omega(4n);
    }

    .readmore {
      text-align: right;
      padding: 0 15px 5px;
      display: block;
    }

    img {
      border-radius: 0;
      border: none;
      box-shadow: none;
      width: 100%;
    }
  }

  h4 {
    text-shadow: 1px 1px 1px #000;
  }

  .equaltitle {
    text-align: center;
    padding: 10px 0;
    background-color: $main-colour;
    color: $alt-colour;
    font-weight: 700;
    text-shadow: none;
  }

  .equalise {
    padding: 0 5px;

    @include media ($full-screen) {
      padding: 0 10px;
    }

  }


}