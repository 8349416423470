.gallery {
  text-align: center;

  img {
    display: inline-block;
    height: 80px;
    margin: 10px;

    @include media ($medium-screen) {
      height:100px;
    }

    @include media ($large-screen) {
      height:120px;
    }
  }

}