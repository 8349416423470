.testimonials {
  @include clearfix();
  min-height: 100px;
  font-size: .8em;

  @include media ($large-screen) {
    font-size: 1em;
  }

  blockquote {
    display: block;
    padding: 5px 35px 5px 35px;
    margin: 0;
    position: relative;
    font-size: 1.1em;
    color: $action-color;
    font-style: italic;
    font-weight: 400;

    &:before {
      content: "\201C"; /*Unicode for Left Double Quote*/

      /*Font*/
      font-family: Georgia, serif;
      font-size: 45px;
      font-weight: bold;
      color: $alt-colour;

      /*Positioning*/
      position: absolute;
      left: 5px;
      top: -10px;
    }

    &:after {
      content: "\201D"; /*Unicode for Left Double Quote*/

      /*Font*/
      font-family: Georgia, serif;
      font-size: 45px;
      font-weight: bold;
      color: $alt-colour;

      /*Positioning*/
      position: absolute;
      right: 10px;
      bottom: -30px;
    }
  }

  h3 {
    font-weight: 400;
    line-height: 1;
    margin: 0;
    text-shadow: 1px 1px 1px $alt-colour;
  }

  h5 {
    font-style: italic;
    font-weight: 400;
    margin: 0;
  }

  .testname,
  .rating {
    @include box-sizing(border-box);
    padding-left: 30px;

    img {
      border: none;
      box-shadow: none;
      display: inline-block;
      margin: 0 0 -3px;
      padding: 0;
      height: 1em;
    }

    @include media ($medium-screen) {
      padding-left: 70px;
      width: 50%;
      float: left;
    }
  }

  @include media ($medium-screen) {

    .rating {
      text-align: right;
      padding-left: 0;
      padding-right: 70px;
    }

  }

  .buttons {
    text-align: center;

    label {
      display: inline-block;
    }

    .formodal {
      font-size: 1em !important;
    }

    .modal-trigger,
    button,
    a {
      @include transition(all 0.3s ease-in-out);
      @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
              radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
      @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s);

      cursor: pointer;
      border: solid $border-size $border-color;
      border-radius: 5px;
      color: $navigation-color-hover;
      background-color: $navigation-background-hover;
      font-weight: 400;
      margin: 5px;
      padding: 5px 20px;
      display: inline-block;
      line-height: 1;
      text-shadow: 1px 1px 2px $shadow-colour;
      box-shadow: black 2px 2px 5px;

      &:hover {
        font-weight: 400;
        color: darken($navigation-color-hover, 10%);
      }
    }
  }

  .testimonial-list {

    .testimonial-text {
      @include clearfix();
      padding: 20px;

      &:nth-child(even) {
        background-color: rgba(0, 0, 0, .05);
      }
    }
  }

  h2 {
    color: $dark-logo-colour;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    text-shadow: 0 0 5px $alt-colour;

    @include media ($large-screen) {
      font-size: 2.4em;
    }
  }

  .cycle-slide {
    width: 100%;
    height: auto
  }

  .testimonial-wrap {
    @include clearfix();
    margin-bottom: 10px;

    .testimonial-text {
      height: auto;
      width: 100%;
    }

    @include media ($medium-screen) {
      p {
        padding: 5px 80px;
        line-height: 1.2;
      }
    }
  }
}
.colourbg {
  background-color: lighten($main-colour, 25%);
  border-top: solid 1px $border-color;
}
