.topbar {
  height: 34px;
  background-color: $alt-colour;

  .social {

    max-width: $max-inner-width;
    margin: auto;
    text-align: right;
    padding-right: 10px;

    svg {
      @include transition(all 0.3s ease-in-out);
      height: 18px;
      margin: 8px;
      fill: #ffffff;

      &:hover {
        fill: $main-colour;
      }
    }
  }
}

header {
  @include clearfix();
  @include box-sizing(border-box);
  margin-left: 10px;
  margin-right: 10px;

  .headerwrap {
    max-width: $max-inner-width;
    margin: auto;
  }

  .logo {
    @include box-sizing(border-box);
    overflow: hidden;

    text-align: left;
    width: 100%;
    padding: 0;

    h1 {
      line-height: 0;
      margin: 0;
    }

    @include media ($medium-screen) {
      float: left;
      text-align: left;
      width: 50%;

      img,
      svg {
        max-height: 110px;
        margin: 0;
      }
    }

    @include media ($large-screen) {
      img,
      svg {
        max-height: 130px;
      }
    }

    @include media ($full-screen) {
      padding: 0;
    }

  }

  .rightheader {
    text-align: center;
    width: 100%;

    svg {
      height: 24px;
      fill: #000000;
      margin: 0 3px -1px 0;
    }

    .email {
      svg {
        height: 18px;
        margin: 0 3px -3px 0;
      }
    }

    h2 {
      font-size: 2.4em;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 5px;
      margin-top: 5px;
      text-shadow: 1px 1px 2px $mid-shadow-colour;

      a {
        color: #000000;
      }
    }

    h3 {
      font-size: 2.1em;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      text-shadow: 1px 1px 2px $mid-shadow-colour;
    }

    h4 {
      font-size: 1.3em;
      font-weight: 400;
      line-height: .8;
      margin-bottom: 10px;
      margin-top: 10px;
      text-shadow: 1px 1px 2px $shadow-colour;
    }

    @include media ($medium-screen) {
      float: left;
      padding-top: 5px;
      text-align: right;
      width: 50%;

      svg {
        height: 28px;
      }

      .email {
        svg {
          height: 20px;
        }
      }

      h2 {
        font-size: 2.8em;
        margin: 0;
      }

      h3 {
        font-size: 1.2em;
        margin: auto;
      }

      h4 {
        font-weight: 400;
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 5px;
        letter-spacing: .03em;
      }

      .email {
        h4 {
          letter-spacing: 0;
          font-size: 1.2em;
        }
      }
    }

    @include media ($large-screen) {

      svg {
        height: 32px;
      }

      .email {
        svg {
          height: 22px;
        }
      }

      h2 {
        font-size: 3.2em;
        margin: 0;
      }

      h3 {
        text-align: left;
        font-size: 1.7em;
      }

      h4 {
        font-size: 2.1em;
      }

      .email {
        h4 {
          margin-top: 3px;
          letter-spacing: 0;
          font-size: 1.5em;
        }
      }
    }
  }
}
