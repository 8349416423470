footer {
  background-color: $nav-footer-background-color;
  background-image: url("/images/bg-glass.png");
  background-position: center;
  background-size: cover;
  border-top: solid 1px $border-color;

  @include media ($medium-screen) {
    padding-top: 5px;
  }

  .footer-wrap {
    max-width: $max-inner-width;
    margin: auto;
  }

  .footer {
    @include clearfix;

    color: $navigation-color;
    text-align: center;
    padding: 0 10px;
    text-shadow: 1px 1px 1px #000;
    margin: 10px 10px 0;

    @include media ($large-screen) {
      margin: 30px 10px 0;
    }

    .footer-links {
      min-height: 80px;
    }

    li {

      & span {
        display: none;
      }

      @include media ($large-screen) {
        display: inline;

        &:first-child {
          margin-left: -10px;
        }

        & span {
          display: inline-block;
        }
      }

      a {
        @include transition(all 0.3s ease-in-out);

        color: $navigation-color;
        font-weight: 400;
        text-shadow: 1px 1px 1px #000;
        display: block;
        font-size: .9em;
        padding-top: 10px;

        &:hover {
          color: $navigation-active-link-color;
        }

        @include media ($large-screen) {
          display: inline-block;
          font-size: 1em;
          padding: 0;
          width: 19%;
        }
      }
    }
  }

  .credwrap {
    margin: 10px 0;

    @include media ($large-screen) {
      margin: 0;
    }
  }
  .copy,
  .credit {
    color: #ffffff;
    font-size: 0.8em;
    text-align: center;

    p {
      margin: 0;
    }

    a {
      color: $alt-colour;
      display: inline-block;

      &:hover {
        @include transition(all 0.3s ease-in-out);
        color: #ffffff;
      }
    }

    @include media ($large-screen) {
      @include span-columns(6);

      p {
        margin-bottom: 30px;
      }
    }
  }

  .copy {
    @include media ($large-screen) {
      text-align: left;
    }
  }

  .credit {
    @include media ($large-screen) {
      text-align: right;
    }
  }

}

.wrapper {
  @include clearfix;
}
