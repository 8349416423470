@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900,300italic,400italic,100,100italic");
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Address styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

button, input[type="button"], input[type="reset"], input[type="submit"],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  background-color: #5c5c5c;
  border-radius: 10px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 0.9em;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus,
  button:hover,
  button:focus {
    background-color: #363636;
    color: #fff; }
  button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled,
  button:disabled {
    cursor: not-allowed;
    opacity: 0.5; }

fieldset {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin: 0 0 0.75em;
  padding: 1.5em; }

input,
label,
select {
  display: block;
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 0.9em; }

label {
  font-weight: 600;
  margin-bottom: 0.375em; }
  label.required::after {
    content: "*"; }
  label abbr {
    display: none; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea, select,
select[multiple=multiple],
textarea {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 0.9em;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, textarea:hover, select:hover,
  select[multiple=multiple]:hover,
  textarea:hover {
    border-color: #c4c4c4; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, textarea:focus, select:focus,
  select[multiple=multiple]:focus,
  textarea:focus {
    border-color: #5c5c5c;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(79, 79, 79, 0.7);
    outline: none; }
  input[type="color"].error, input[type="date"].error, input[type="datetime"].error, input[type="datetime-local"].error, input[type="email"].error, input[type="month"].error, input[type="number"].error, input[type="password"].error, input[type="search"].error, input[type="tel"].error, input[type="text"].error, input[type="time"].error, input[type="url"].error, input[type="week"].error, textarea.error, select.error,
  select[multiple=multiple].error,
  textarea.error {
    border-color: red;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(230, 0, 0, 0.7);
    outline: none; }
  input[type="color"].valid, input[type="date"].valid, input[type="datetime"].valid, input[type="datetime-local"].valid, input[type="email"].valid, input[type="month"].valid, input[type="number"].valid, input[type="password"].valid, input[type="search"].valid, input[type="tel"].valid, input[type="text"].valid, input[type="time"].valid, input[type="url"].valid, input[type="week"].valid, textarea.valid, select.valid,
  select[multiple=multiple].valid,
  textarea.valid {
    border-color: green;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(0, 103, 0, 0.7);
    outline: none; }

textarea {
  resize: vertical; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

input[type="file"] {
  padding-bottom: 0.75em;
  width: 100%; }

select {
  max-width: 100%;
  width: auto; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: bold;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

table {
  -webkit-font-feature-settings: "kern", "liga", "tnum";
  -moz-font-feature-settings: "kern", "liga", "tnum";
  -ms-font-feature-settings: "kern", "liga", "tnum";
  font-feature-settings: "kern", "liga", "tnum";
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #b7b7b7;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  -webkit-font-feature-settings: "kern", "liga", "pnum";
  -moz-font-feature-settings: "kern", "liga", "pnum";
  -ms-font-feature-settings: "kern", "liga", "pnum";
  font-feature-settings: "kern", "liga", "pnum";
  -webkit-font-smoothing: antialiased;
  color: #333;
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 0.9em;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 0.9em;
  line-height: 1.2;
  margin: 0 0 0.75em; }

h1 {
  font-size: 2.48832em; }

h2 {
  font-size: 2.0736em; }

h3 {
  font-size: 1.728em; }

h4 {
  font-size: 1.44em; }

h5 {
  font-size: 1.2em; }

h6 {
  font-size: 1em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #5c5c5c;
  text-decoration: none;
  transition: color 0.1s linear; }
  a:active, a:focus, a:hover {
    color: #363636; }
  a:active, a:focus {
    outline: none; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

@font-face {
  font-family: 'lg';
  src: url("../fonts/lg.eot?n1z373");
  src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal; }

.lg-icon {
  font-family: 'lg';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  border: none;
  outline: none; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }
  .lg-actions .lg-next:before {
    content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45); }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .lg-toolbar .lg-icon:hover {
      color: #FFF; }
  .lg-toolbar .lg-close:after {
    content: "\e070"; }
  .lg-toolbar .lg-download:after {
    content: "\e0f2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }
  .lg-sub-html h4 {
    margin: 0;
    font-size: 12px;
    font-weight: bold; }
  .lg-sub-html p {
    font-size: 10px;
    line-height: 1.1;
    margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }
  @media (min-width: 1025px) {
    .lg-outer .lg-thumb-item {
      -webkit-transition: border-color 0.25s ease;
      -o-transition: border-color 0.25s ease;
      transition: border-color 0.25s ease; } }
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #a90707; }
  .lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px; }
  .lg-outer .lg-toogle-thumb:after {
    content: "\e1ff"; }
  .lg-outer .lg-toogle-thumb:hover {
    color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }
  .lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }
  #lg-actual-size:after {
    content: "\e033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }
  #lg-zoom-out:after {
    content: "\e312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }
  .lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }
  .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }
  .lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px; }
  .lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
    box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }
  .lg-fullscreen-on .lg-fullscreen:after {
    content: "\e20d"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }
  .lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px; }
  .lg-outer .lg-dropdown > li:last-child {
    margin-bottom: 0px; }
  .lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
    color: #333; }
  .lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px; }
    .lg-outer .lg-dropdown a:hover {
      background-color: rgba(0, 0, 0, 0.07); }
  .lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle; }
  .lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto; }

.lg-outer #lg-share {
  position: relative; }
  .lg-outer #lg-share:after {
    content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }
  .lg-outer #lg-share-facebook .lg-icon:after {
    content: "\e901"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }
  .lg-outer #lg-share-twitter .lg-icon:after {
    content: "\e904"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }
  .lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\e902"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }
  .lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\e903"; }

.lg-group:after {
  content: "";
  display: table;
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("../images/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item, .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    .lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-outer.lg-hide-download #lg-download {
    display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 0.95; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.topbar {
  height: 34px;
  background-color: #0094da; }
  .topbar .social {
    max-width: 1400px;
    margin: auto;
    text-align: right;
    padding-right: 10px; }
    .topbar .social svg {
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      height: 18px;
      margin: 8px;
      fill: #ffffff; }
      .topbar .social svg:hover {
        fill: #a3a3a3; }

header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 10px;
  margin-right: 10px; }
  header::after {
    clear: both;
    content: "";
    display: table; }
  header .headerwrap {
    max-width: 1400px;
    margin: auto; }
  header .logo {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    text-align: left;
    width: 100%;
    padding: 0; }
    header .logo h1 {
      line-height: 0;
      margin: 0; }
    @media screen and (min-width: 40em) {
      header .logo {
        float: left;
        text-align: left;
        width: 50%; }
        header .logo img,
        header .logo svg {
          max-height: 110px;
          margin: 0; } }
    @media screen and (min-width: 53.75em) {
      header .logo img,
      header .logo svg {
        max-height: 130px; } }
    @media screen and (min-width: 75em) {
      header .logo {
        padding: 0; } }
  header .rightheader {
    text-align: center;
    width: 100%; }
    header .rightheader svg {
      height: 24px;
      fill: #000000;
      margin: 0 3px -1px 0; }
    header .rightheader .email svg {
      height: 18px;
      margin: 0 3px -3px 0; }
    header .rightheader h2 {
      font-size: 2.4em;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 5px;
      margin-top: 5px;
      text-shadow: 1px 1px 2px #a3a3a3; }
      header .rightheader h2 a {
        color: #000000; }
    header .rightheader h3 {
      font-size: 2.1em;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      text-shadow: 1px 1px 2px #a3a3a3; }
    header .rightheader h4 {
      font-size: 1.3em;
      font-weight: 400;
      line-height: .8;
      margin-bottom: 10px;
      margin-top: 10px;
      text-shadow: 1px 1px 2px rgba(189, 189, 189, 0.7); }
    @media screen and (min-width: 40em) {
      header .rightheader {
        float: left;
        padding-top: 5px;
        text-align: right;
        width: 50%; }
        header .rightheader svg {
          height: 28px; }
        header .rightheader .email svg {
          height: 20px; }
        header .rightheader h2 {
          font-size: 2.8em;
          margin: 0; }
        header .rightheader h3 {
          font-size: 1.2em;
          margin: auto; }
        header .rightheader h4 {
          font-weight: 400;
          line-height: 1.2;
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 5px;
          letter-spacing: .03em; }
        header .rightheader .email h4 {
          letter-spacing: 0;
          font-size: 1.2em; } }
    @media screen and (min-width: 53.75em) {
      header .rightheader svg {
        height: 32px; }
      header .rightheader .email svg {
        height: 22px; }
      header .rightheader h2 {
        font-size: 3.2em;
        margin: 0; }
      header .rightheader h3 {
        text-align: left;
        font-size: 1.7em; }
      header .rightheader h4 {
        font-size: 2.1em; }
      header .rightheader .email h4 {
        margin-top: 3px;
        letter-spacing: 0;
        font-size: 1.5em; } }

.modal .modal-fade-screen {
  -webkit-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  padding-top: 0.6em;
  text-align: left;
  visibility: hidden;
  z-index: 99999999999; }
  @media screen and (min-width: 53.75em) {
    .modal .modal-fade-screen {
      padding-top: 10em; } }
  .modal .modal-fade-screen .modal-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer; }

.modal .modal-state {
  display: none; }

.modal .modal-close {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  height: 1.5em;
  width: 1.5em;
  background: #fff;
  cursor: pointer; }
  .modal .modal-close:after, .modal .modal-close:before {
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 0;
    left: 50%;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    height: 1.5em;
    width: 0.15em;
    background: #ddd;
    content: '';
    display: block;
    margin: -3px 0 0 -1px; }
  .modal .modal-close:hover:after, .modal .modal-close:hover:before {
    background: #c4c4c4; }
  .modal .modal-close:before {
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }

.modal .modal-inner {
  -webkit-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  background: #fff;
  border-radius: 10px;
  margin-top: 0;
  margin: auto;
  max-height: 95%;
  overflow: auto;
  padding: 1.5em;
  position: relative;
  width: 95%;
  max-width: 500px; }
  @media screen and (min-width: 40em) {
    .modal .modal-inner {
      max-height: 85%;
      padding: 3em;
      width: 60%; } }
  @media screen and (min-width: 53.75em) {
    .modal .modal-inner {
      width: 50%; } }
  .modal .modal-inner h1 {
    font-size: 1.4em;
    margin-bottom: 0 0 0.6em 0;
    text-align: left;
    text-transform: capitalize; }
    @media screen and (min-width: 53.75em) {
      .modal .modal-inner h1 {
        font-size: 1.6em; } }
  .modal .modal-inner p {
    font-size: .9em;
    color: #333;
    line-height: 1.5; }
  .modal .modal-inner .modal-intro {
    margin-bottom: 1em; }
  .modal .modal-inner .modal-content {
    color: #333; }
    @media screen and (min-width: 40em) {
      .modal .modal-inner .modal-content {
        -webkit-columns: 2 8em;
        -moz-columns: 2 8em;
        columns: 2 8em; } }
  .modal .modal-inner .modal-footer {
    text-align: center; }
  .modal .modal-inner a.cta {
    color: #fff;
    display: inline-block;
    margin-right: 0.5em;
    margin-top: 1em; }
    .modal .modal-inner a.cta:last-child {
      padding: 0 2em; }

.modal #feedback-form {
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .modal #feedback-form .formgroup {
    display: block; }
    .modal #feedback-form .formgroup label {
      text-shadow: 1px 1px 1px rgba(112, 112, 112, 0.7); }
    @media screen and (min-width: 53.75em) {
      .modal #feedback-form .formgroup label {
        display: inline-block;
        text-align: right;
        width: 25%; } }
  @media screen and (min-width: 53.75em) and (min-width: 75em) {
    .modal #feedback-form .formgroup label {
      font-size: 1.2em; } }
    @media screen and (min-width: 53.75em) {
      .modal #feedback-form .formgroup .messagelabel {
        vertical-align: top;
        padding-top: 1em; }
      .modal #feedback-form .formgroup #feedbackname, .modal #feedback-form .formgroup #feedbackrating, .modal #feedback-form .formgroup #feedbackcomments {
        display: inline-block;
        width: 70%; } }
  .modal #feedback-form .disp-error {
    text-align: center;
    color: red;
    text-shadow: 1px 1px 1px #000;
    margin-bottom: 15px; }
    .modal #feedback-form .disp-error label {
      display: block; }

.modal .form-errors {
  display: block;
  text-align: center;
  font-weight: 700;
  color: red; }

.modal .modal-state:checked + .modal-fade-screen {
  opacity: 1;
  visibility: visible; }

.modal .modal-state:checked + .modal-fade-screen .modal-inner {
  top: 0.5em; }

.modal .error {
  color: red;
  line-height: 1;
  margin-top: -5px; }

.modal-open {
  overflow: hidden; }

.nav-wrap {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 0;
  min-height: 50px; }
  .nav-wrap::after {
    clear: both;
    content: "";
    display: table; }
  .nav-wrap .navigation {
    font-size: 1.2em;
    text-align: center;
    z-index: 999; }
    .nav-wrap .navigation a {
      text-decoration: none; }
    .nav-wrap .navigation .navigation-wrapper {
      max-width: 1400px;
      margin: auto;
      position: relative;
      z-index: 9999; }
      .nav-wrap .navigation .navigation-wrapper::after {
        clear: both;
        content: "";
        display: table; }
    .nav-wrap .navigation .navigation-menu-button {
      color: #050607;
      display: block;
      float: right;
      font-weight: 700;
      line-height: 50px;
      margin: 0;
      padding-right: 1em;
      text-transform: uppercase; }
      @media screen and (min-width: 53.75em) {
        .nav-wrap .navigation .navigation-menu-button {
          display: none; } }
      .nav-wrap .navigation .navigation-menu-button:focus, .nav-wrap .navigation .navigation-menu-button:hover {
        color: #0094da; }
    .nav-wrap .navigation nav {
      float: none;
      min-height: 50px;
      z-index: 9999999; }
    .nav-wrap .navigation ul.navigation-menu {
      -webkit-transform-style: preserve-3d;
      clear: both;
      display: none;
      margin: 0 auto;
      overflow: visible;
      padding: 0;
      width: 100%;
      z-index: 9999;
      position: relative; }
      .nav-wrap .navigation ul.navigation-menu.show {
        display: block; }
      @media screen and (min-width: 53.75em) {
        .nav-wrap .navigation ul.navigation-menu {
          display: inline;
          margin: 0;
          padding: 0; } }
    .nav-wrap .navigation ul li.nav-link {
      background: #eeeeee;
      display: block;
      line-height: 50px;
      overflow: hidden;
      text-align: right;
      padding-right: 20px;
      width: 100%;
      z-index: 9999; }
      @media screen and (min-width: 53.75em) {
        .nav-wrap .navigation ul li.nav-link {
          background: transparent;
          display: inline;
          line-height: 50px;
          text-align: center;
          text-decoration: none;
          padding: 0;
          border-right: dashed 1px #0094da; }
          .nav-wrap .navigation ul li.nav-link:last-child {
            border-right: none; } }
      .nav-wrap .navigation ul li.nav-link a {
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        color: #050607;
        display: inline-block;
        font-weight: 400; }
        @media screen and (min-width: 53.75em) {
          .nav-wrap .navigation ul li.nav-link a {
            margin: 0;
            padding: 0;
            width: 19%; } }
        .nav-wrap .navigation ul li.nav-link a.active-nav-item, .nav-wrap .navigation ul li.nav-link a:focus, .nav-wrap .navigation ul li.nav-link a:hover {
          color: #0094da; }
      .nav-wrap .navigation ul li.nav-link:first-child a.active-nav-item, .nav-wrap .navigation ul li.nav-link:first-child a:focus, .nav-wrap .navigation ul li.nav-link:first-child a:hover, .nav-wrap .navigation ul li.nav-link:first-child a:before {
        border-bottom-left-radius: 7px;
        border-top-left-radius: 7px; }
      .nav-wrap .navigation ul li.nav-link:last-child a.active-nav-item, .nav-wrap .navigation ul li.nav-link:last-child a:focus, .nav-wrap .navigation ul li.nav-link:last-child a:hover, .nav-wrap .navigation ul li.nav-link:last-child a:before {
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px; }
    .nav-wrap .navigation li.more.nav-link {
      padding-right: 0; }
      .nav-wrap .navigation li.more.nav-link a {
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        margin-right: 1em; }
      .nav-wrap .navigation li.more.nav-link > a {
        padding-right: 0.6em; }
      .nav-wrap .navigation li.more.nav-link > a:after {
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: auto;
        right: -0.4em;
        bottom: auto;
        left: auto;
        color: #050607;
        content: "\25BE"; }
      .nav-wrap .navigation li.more.nav-link > a.active-nav-item:after, .nav-wrap .navigation li.more.nav-link > a:focus:after, .nav-wrap .navigation li.more.nav-link > a:hover:after {
        color: #0094da; }
      @media screen and (min-width: 53.75em) {
        .nav-wrap .navigation li.more.nav-link a {
          margin-right: 0; }
        .nav-wrap .navigation li.more.nav-link > a {
          padding-right: 0; }
        .nav-wrap .navigation li.more.nav-link > a:after {
          position: absolute;
          top: auto;
          right: 0.2em;
          bottom: auto;
          left: auto;
          color: #050607;
          content: "\25BE"; }
        .nav-wrap .navigation li.more.nav-link > a.active-nav-item:after, .nav-wrap .navigation li.more.nav-link > a:focus:after, .nav-wrap .navigation li.more.nav-link > a:hover:after {
          color: #0094da; } }
    .nav-wrap .navigation li.more {
      overflow: visible;
      padding-right: 0; }
      .nav-wrap .navigation li.more a {
        padding-right: 1em; }
      .nav-wrap .navigation li.more > a {
        padding-right: 1.6em;
        position: relative; }
        @media screen and (min-width: 53.75em) {
          .nav-wrap .navigation li.more > a {
            margin-right: 1em; } }
        .nav-wrap .navigation li.more > a:after {
          content: "›";
          font-size: 1.2em;
          position: absolute;
          right: 0.5em; }
      .nav-wrap .navigation li.more:focus > .submenu,
      .nav-wrap .navigation li.more:hover > .submenu {
        max-height: 2000px; }
        @media screen and (min-width: 53.75em) {
          .nav-wrap .navigation li.more:focus > .submenu,
          .nav-wrap .navigation li.more:hover > .submenu {
            border-color: #0094da; } }
      @media screen and (min-width: 53.75em) {
        .nav-wrap .navigation li.more {
          padding-right: 1em;
          position: relative; } }
    .nav-wrap .navigation ul.submenu {
      -webkit-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      max-height: 0;
      padding-left: 0; }
      @media screen and (min-width: 53.75em) {
        .nav-wrap .navigation ul.submenu {
          border: solid 1px transparent;
          border-radius: 10px;
          overflow: hidden;
          right: -1em;
          position: absolute;
          top: 1.5em; } }
      @media screen and (min-width: 53.75em) {
        .nav-wrap .navigation ul.submenu .submenu {
          left: 10em;
          top: 0; } }
      .nav-wrap .navigation ul.submenu li {
        display: block;
        padding-right: 0;
        line-height: 1.2;
        border-bottom: solid 1px #fbfbfb; }
        @media screen and (min-width: 53.75em) {
          .nav-wrap .navigation ul.submenu li {
            font-size: 0.8em; }
            .nav-wrap .navigation ul.submenu li:first-child > a {
              border-top-left-radius: 7px;
              border-top-right-radius: 7px; }
              .nav-wrap .navigation ul.submenu li:first-child > a.active-nav-item, .nav-wrap .navigation ul.submenu li:first-child > a:focus, .nav-wrap .navigation ul.submenu li:first-child > a:hover, .nav-wrap .navigation ul.submenu li:first-child > a:before {
                border-top-left-radius: 7px;
                border-top-right-radius: 7px; }
            .nav-wrap .navigation ul.submenu li:last-child > a {
              border-bottom-left-radius: 7px;
              border-bottom-right-radius: 7px;
              border-bottom: none; }
              .nav-wrap .navigation ul.submenu li:last-child > a.active-nav-item, .nav-wrap .navigation ul.submenu li:last-child > a:focus, .nav-wrap .navigation ul.submenu li:last-child > a:hover, .nav-wrap .navigation ul.submenu li:last-child > a:before {
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px; } }
        .nav-wrap .navigation ul.submenu li a {
          background-color: #e1e1e1;
          display: inline-block;
          text-align: right;
          text-decoration: none;
          width: 100%;
          padding: 10px;
          margin: 0 !important; }
          @media screen and (min-width: 53.75em) {
            .nav-wrap .navigation ul.submenu li a {
              background-color: #eeeeee;
              padding: 10px;
              text-align: left;
              width: 17em; } }

.clearfix::after {
  clear: both;
  content: "";
  display: table; }

.top-wrap {
  max-height: 1000px !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 720px; }
  .top-wrap::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (min-width: 53.75em) {
    .top-wrap {
      min-height: 530px; } }

.hero-wrap {
  box-shadow: black 0 2px 7px;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-top: solid 1px #0094da;
  border-bottom: solid 1px #0094da; }
  .hero-wrap::after {
    clear: both;
    content: "";
    display: table; }

.slidecontainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column; }
  .slidecontainer::after {
    clear: both;
    content: "";
    display: table; }

.cycle-pager {
  overflow: hidden;
  text-align: left;
  padding-left: 30px;
  bottom: 0;
  width: 100%;
  z-index: 999; }
  @media screen and (min-width: 75em) {
    .cycle-pager {
      width: 1400px;
      display: block;
      margin: auto; } }
  .cycle-pager span {
    color: #a3a3a3;
    cursor: pointer;
    display: inline-block;
    font-size: 50px;
    height: 16px;
    width: 16px; }
  .cycle-pager .cycle-pager-active {
    color: #0094da; }
  .cycle-pager > * {
    cursor: pointer; }

.cycle-prev,
.cycle-next {
  position: absolute;
  top: 0;
  width: 20%;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 999;
  height: 100%;
  cursor: pointer; }

.cycle-prev {
  left: 0;
  background: url(/images/left.png) 5% 50% no-repeat; }

.cycle-next {
  right: 0;
  background: url(/images/right.png) 95% 50% no-repeat; }

.cycle-prev:hover,
.cycle-next:hover {
  opacity: .7;
  filter: alpha(opacity=70); }

.disabled {
  opacity: .5;
  filter: alpha(opacity=50); }

.hero {
  /*
  $hero-image6: 'http://lorempixel.com/1000/400/nature/1';
  */
  flex-grow: 1;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 270px;
  /*
  .hero-img5 {
    background-position: top;

    .hero-copy {
      background: none;

      p {
        text-shadow: 1px 1px 1px rgba(255,255,255,1);
        color: #000;

        &.offerterms {
          color: $alt-colour;
          font-size: 1.3em;
          text-shadow: 1px 1px 1px rgba(0,0,0,1);

          @include media($medium-screen) {
            font-size: 1.5em;
          }

          @include media($large-screen) {
            font-size: 2em;
          }
        }
      }
    }
  }
  */ }
  @media screen and (min-width: 75em) {
    .hero {
      min-height: 400px; } }
  .hero .hero-img1 {
    background: url("/images/banner1.jpg");
    background-position: center right;
    justify-content: center;
    align-items: center; }
    @media screen and (min-height: 460px) and (min-width: 53.75em) {
      .hero .hero-img1 {
        justify-content: flex-start;
        align-items: center;
        padding: 2% 2%; } }
  .hero .hero-img2 {
    background: url("/images/banner2.jpg");
    background-position: center;
    justify-content: center;
    align-items: center; }
    @media screen and (min-height: 460px) and (min-width: 53.75em) {
      .hero .hero-img2 {
        justify-content: flex-end;
        align-items: flex-start;
        padding: 5% 2% 2%; } }
  .hero .hero-img3 {
    background: url("/images/banner3.jpg");
    background-position: center;
    justify-content: center;
    align-items: center; }
    @media screen and (min-height: 460px) and (min-width: 53.75em) {
      .hero .hero-img3 {
        justify-content: center;
        align-items: flex-end;
        padding: 0 10% 5%; } }
  .hero .hero-img4 {
    background: url("/images/banner4.jpg");
    background-position: center;
    justify-content: center;
    align-items: center; }
    @media screen and (min-height: 460px) and (min-width: 53.75em) {
      .hero .hero-img4 {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 5% 10% 5%; } }
  .hero .hero-img1,
  .hero .hero-img2,
  .hero .hero-img3,
  .hero .hero-img4,
  .hero .hero-img5 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    display: flex; }
    .hero .hero-img1::after,
    .hero .hero-img2::after,
    .hero .hero-img3::after,
    .hero .hero-img4::after,
    .hero .hero-img5::after {
      clear: both;
      content: "";
      display: table; }
  .hero .hero-inner {
    color: #ffffff;
    text-align: center;
    max-width: 90%;
    display: flex; }
    @media screen and (min-height: 460px) and (min-width: 53.75em) {
      .hero .hero-inner {
        max-width: 700px; } }
    .hero .hero-inner .hero-copy {
      text-shadow: 3px 3px 10px #0094da, 3px -3px 10px #0094da, -3px -3px 10px #0094da, -3px 3px 10px #0094da;
      background-color: rgba(0, 0, 0, 0.2);
      display: inline-block;
      padding: 10px 15px 5px;
      border: solid 1px #0094da;
      border-radius: 2px; }
      .hero .hero-inner .hero-copy h2 {
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        font-size: 1.7em;
        margin-bottom: .1em; }
        @media screen and (min-height: 460px) and (min-width: 53.75em) {
          .hero .hero-inner .hero-copy h2 {
            font-size: 2.4em; } }
        @media screen and (min-height: 460px) and (min-width: 75em) {
          .hero .hero-inner .hero-copy h2 {
            font-size: 3em; } }
      .hero .hero-inner .hero-copy p {
        font-weight: 400;
        line-height: 1.1em; }
        @media screen and (min-height: 460px) and (min-width: 53.75em) {
          .hero .hero-inner .hero-copy p {
            font-size: 1.4em; } }
  .hero .heronav {
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 100%;
    z-index: 9999999; }
    .hero .heronav span {
      color: #ddd;
      cursor: pointer;
      display: inline-block;
      font-size: 50px;
      height: 16px;
      width: 16px; }
      .hero .heronav span .cycle-pager-active {
        color: #D69746; }
    .hero .heronav > * {
      cursor: pointer; }

.lower-hero {
  background-image: url("/images/bg-glass.png");
  background-position: center;
  background-size: cover;
  background-color: #a3a3a3;
  padding: 20px 10px;
  border-top: solid 1px #0094da; }
  .lower-hero .comp-name {
    font-size: 1.2em; }
  .lower-hero p {
    margin: 0;
    font-weight: 700; }
  @media screen and (min-width: 53.75em) {
    .lower-hero {
      font-size: 1.3em; } }

.cta-button {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), -webkit-radial-gradient(#0083c1, #004f74);
  background-image: linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), radial-gradient(#0083c1, #004f74);
  background-size: 100%;
  position: relative;
  z-index: 100;
  cursor: pointer;
  border: solid 3px #0094da;
  border-radius: 5px;
  color: white;
  background-color: #0094da;
  font-weight: 400;
  margin: 5px 0;
  padding: 10px 25px;
  display: inline-block;
  line-height: 1;
  text-shadow: 1px 1px 2px rgba(189, 189, 189, 0.7);
  box-shadow: black 2px 2px 5px; }
  .cta-button:before {
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), -webkit-radial-gradient(#0071a7, #003d5b);
    background-image: linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), radial-gradient(#0071a7, #003d5b);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .cta-button:hover:before {
    opacity: 1; }
  .cta-button:hover {
    font-weight: 400;
    color: #e6e6e6; }

.contentwrap {
  max-width: 1400px;
  margin: auto; }

.content,
.boxes {
  font-size: .8em;
  font-weight: 400;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); }
  .content::after,
  .boxes::after {
    clear: both;
    content: "";
    display: table; }
  .content p,
  .boxes p {
    font-size: 1em;
    margin-bottom: 0.5em; }
    .content p.callout,
    .boxes p.callout {
      text-align: center;
      font-weight: 400;
      color: #0094da;
      text-shadow: 1px 1px 1px #000; }
  .content h2,
  .boxes h2 {
    font-weight: 400;
    color: #0094da;
    font-size: 2.4em;
    margin-top: 10px;
    margin-bottom: 5px;
    text-shadow: 1px 1px 1px #000; }
  .content h3,
  .content h4,
  .boxes h3,
  .boxes h4 {
    font-weight: 700;
    margin: 0; }
  .content h6,
  .boxes h6 {
    line-height: 1.4;
    font-size: 1.3em;
    font-weight: 400; }
  .content .note,
  .boxes .note {
    font-size: .7em;
    font-weight: normal;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); }
  .content a,
  .boxes a {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-weight: 400;
    color: #0094da; }
    .content a:hover,
    .boxes a:hover {
      color: #00608e;
      font-weight: 400; }
  .content .indent,
  .boxes .indent {
    margin-left: 20px; }
  @media screen and (min-width: 53.75em) {
    .content h6,
    .boxes h6 {
      font-size: 1.4em; }
    .content p,
    .boxes p {
      font-size: 1.2em; } }
  @media screen and (min-width: 75em) {
    .content h6,
    .boxes h6 {
      font-size: 1.6em; }
    .content p,
    .boxes p {
      font-size: 1.3em; } }

.content {
  padding: 0;
  margin: 0 10px; }
  .content .main-col h3 {
    font-weight: 400;
    color: #0094da;
    margin-top: 10px;
    margin-bottom: 5px;
    text-shadow: 1px 1px 1px #000; }
  @media screen and (min-width: 53.75em) {
    .content .main-col {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 74.4105871005%; }
      .content .main-col:last-child {
        margin-right: 0; } }
  .content .fb-wrapper {
    margin: 20px auto 10px;
    max-width: 500px; }
    .content .fb-wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .content .fb-wrapper iframe {
      border-radius: 3px;
      box-shadow: black 2px 2px 7px; }
  .content .aside .payments p {
    text-align: center;
    font-size: 1.2em;
    font-weight: 400; }
  .content .aside .payments img {
    border: none;
    border-radius: 0;
    padding: 0;
    box-shadow: none; }
  @media screen and (min-width: 53.75em) {
    .content .aside {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 23.2317613015%;
      border: none;
      border-radius: 2px;
      padding: 0; }
      .content .aside:last-child {
        margin-right: 0; }
      .content .aside .openhours p {
        font-size: 1em; }
      .content .aside .payments p {
        font-size: 1em; } }
  @media screen and (min-width: 75em) {
    .content .aside {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 23.2317613015%; }
      .content .aside:last-child {
        margin-right: 0; }
      .content .aside .openhours p {
        font-size: 1.3em; } }
  .content aside {
    text-align: center; }
    .content aside h2 {
      text-align: left; }
    @media screen and (min-width: 53.75em) {
      .content aside {
        text-align: left; } }
  .content .contact-details {
    max-width: 450px;
    margin: auto; }
    .content .contact-details h3 {
      font-size: 1.7em; }
      .content .contact-details h3 .nowrap-numbers {
        display: inline-block;
        color: #333333;
        text-shadow: inherit; }
  .content img {
    border: solid 1px #0094da;
    border-radius: 10px;
    box-shadow: black 2px 2px 7px;
    display: block;
    height: auto;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding: 3px; }
    .content img.fright {
      margin: 10px auto; }
      @media screen and (min-width: 53.75em) {
        .content img.fright {
          float: right;
          margin: 10px 0 10px 10px;
          max-width: 30%; } }
    .content img.info-fright {
      margin: 10px auto; }
      @media screen and (min-width: 40em) {
        .content img.info-fright {
          float: right;
          margin: 10px 0 10px 10px;
          max-width: 45%; } }
    .content img.about-fright {
      margin: 10px auto; }
      @media screen and (min-width: 40em) {
        .content img.about-fright {
          float: right;
          margin: 10px 0 10px 10px;
          width: 30%;
          max-width: 300px; } }
    .content img.fleft {
      margin: 10px auto; }
      @media screen and (min-width: 53.75em) {
        .content img.fleft {
          float: left;
          margin: 10px 10px 10px 0;
          max-width: 35%; } }
    .content img.about-fleft {
      margin: 10px auto; }
      @media screen and (min-width: 40em) {
        .content img.about-fleft {
          float: left;
          margin: 10px 10px 10px 0;
          width: 35%;
          max-width: 400px; } }
    .content img.divider {
      border: 0;
      box-shadow: none;
      padding: 0;
      margin: 0 auto 25px;
      width: 85%; }

.radio {
  text-align: center; }
  .radio h2,
  .radio audio {
    vertical-align: middle;
    display: inline-block;
    max-width: 100%; }
  .radio h3 {
    font-size: 2.2em;
    font-weight: 400; }
    @media screen and (min-width: 40em) {
      .radio h3 {
        font-size: 3em; } }

.maintxt span,
.maintxt a {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 400;
  color: #0094da;
  text-shadow: 1px 1px 1px black; }
  .maintxt span:hover,
  .maintxt a:hover {
    color: #28baff; }

.about {
  font-size: 1.1em; }
  .about .lead {
    font-size: 1.4em; }

.comp-name {
  color: #050607;
  font-weight: 700 !important;
  font-style: normal;
  font-stretch: expanded; }

.gallery {
  text-align: center; }
  .gallery img {
    display: inline-block;
    height: 80px;
    margin: 10px; }
    @media screen and (min-width: 40em) {
      .gallery img {
        height: 100px; } }
    @media screen and (min-width: 53.75em) {
      .gallery img {
        height: 120px; } }

.boxes {
  background-color: #e3e3e3;
  padding: 10px 10px 20px; }
  .boxes::after {
    clear: both;
    content: "";
    display: table; }
  .boxes .infobox {
    margin-top: 10px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: black 1px 1px 7px; }
    @media screen and (min-width: 25em) and (max-width: 53.75em) {
      .boxes .infobox {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .boxes .infobox:last-child {
          margin-right: 0; }
        .boxes .infobox:nth-child(2n) {
          margin-right: 0; }
        .boxes .infobox:nth-child(2n+1) {
          clear: left; } }
    @media screen and (min-width: 53.75em) {
      .boxes .infobox {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 23.2317613015%; }
        .boxes .infobox:last-child {
          margin-right: 0; }
        .boxes .infobox:nth-child(4n) {
          margin-right: 0; }
        .boxes .infobox:nth-child(4n+1) {
          clear: left; } }
    .boxes .infobox .readmore {
      text-align: right;
      padding: 0 15px 5px;
      display: block; }
    .boxes .infobox img {
      border-radius: 0;
      border: none;
      box-shadow: none;
      width: 100%; }
  .boxes h4 {
    text-shadow: 1px 1px 1px #000; }
  .boxes .equaltitle {
    text-align: center;
    padding: 10px 0;
    background-color: #a3a3a3;
    color: #0094da;
    font-weight: 700;
    text-shadow: none; }
  .boxes .equalise {
    padding: 0 5px; }
    @media screen and (min-width: 75em) {
      .boxes .equalise {
        padding: 0 10px; } }

.services article h3 {
  font-weight: 400;
  color: #0094da;
  margin-top: 10px;
  margin-bottom: 5px;
  text-shadow: 1px 1px 1px #000; }

.services ul {
  font-size: 1.2em;
  display: block;
  list-style-type: disc;
  padding-left: 40px;
  margin-bottom: 15px; }

.sidemenu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  box-shadow: black 2px 2px 7px;
  background-color: #a3a3a3;
  max-width: 400px;
  margin: 10px auto;
  font-size: 1.2em; }
  @media screen and (min-width: 53.75em) {
    .sidemenu {
      margin: 20px 0; } }
  .sidemenu li {
    background: #eeeeee;
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 0;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: black;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: black; }
    .sidemenu li:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-top: none; }
    .sidemenu li:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom: none; }
    .sidemenu li a {
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      display: block;
      color: #050607;
      font-weight: 500;
      text-shadow: 1px 1px 1px #333333; }
      @media screen and (min-width: 53.75em) {
        .sidemenu li a {
          padding: 10px 5px; } }
      .sidemenu li a.navOn, .sidemenu li a:focus, .sidemenu li a:hover {
        color: #a3a3a3;
        background-color: #0094da; }
    .sidemenu li:first-child a.navOn, .sidemenu li:first-child a:focus, .sidemenu li:first-child a:hover {
      border-top-left-radius: 9px;
      border-top-right-radius: 9px; }
    .sidemenu li:last-child a.navOn, .sidemenu li:last-child a:focus, .sidemenu li:last-child a:hover {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px; }

.testimonials {
  min-height: 100px;
  font-size: .8em; }
  .testimonials::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (min-width: 53.75em) {
    .testimonials {
      font-size: 1em; } }
  .testimonials blockquote {
    display: block;
    padding: 5px 35px 5px 35px;
    margin: 0;
    position: relative;
    font-size: 1.1em;
    color: #5c5c5c;
    font-style: italic;
    font-weight: 400; }
    .testimonials blockquote:before {
      content: "\201C";
      /*Unicode for Left Double Quote*/
      /*Font*/
      font-family: Georgia, serif;
      font-size: 45px;
      font-weight: bold;
      color: #0094da;
      /*Positioning*/
      position: absolute;
      left: 5px;
      top: -10px; }
    .testimonials blockquote:after {
      content: "\201D";
      /*Unicode for Left Double Quote*/
      /*Font*/
      font-family: Georgia, serif;
      font-size: 45px;
      font-weight: bold;
      color: #0094da;
      /*Positioning*/
      position: absolute;
      right: 10px;
      bottom: -30px; }
  .testimonials h3 {
    font-weight: 400;
    line-height: 1;
    margin: 0;
    text-shadow: 1px 1px 1px #0094da; }
  .testimonials h5 {
    font-style: italic;
    font-weight: 400;
    margin: 0; }
  .testimonials .testname,
  .testimonials .rating {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 30px; }
    .testimonials .testname img,
    .testimonials .rating img {
      border: none;
      box-shadow: none;
      display: inline-block;
      margin: 0 0 -3px;
      padding: 0;
      height: 1em; }
    @media screen and (min-width: 40em) {
      .testimonials .testname,
      .testimonials .rating {
        padding-left: 70px;
        width: 50%;
        float: left; } }
  @media screen and (min-width: 40em) {
    .testimonials .rating {
      text-align: right;
      padding-left: 0;
      padding-right: 70px; } }
  .testimonials .buttons {
    text-align: center; }
    .testimonials .buttons label {
      display: inline-block; }
    .testimonials .buttons .formodal {
      font-size: 1em !important; }
    .testimonials .buttons .modal-trigger,
    .testimonials .buttons button,
    .testimonials .buttons a {
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), -webkit-radial-gradient(#0083c1, #004f74);
      background-image: linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), radial-gradient(#0083c1, #004f74);
      background-size: 100%;
      position: relative;
      z-index: 100;
      cursor: pointer;
      border: solid 3px #0094da;
      border-radius: 5px;
      color: white;
      background-color: #0094da;
      font-weight: 400;
      margin: 5px;
      padding: 5px 20px;
      display: inline-block;
      line-height: 1;
      text-shadow: 1px 1px 2px rgba(189, 189, 189, 0.7);
      box-shadow: black 2px 2px 5px; }
      .testimonials .buttons .modal-trigger:before,
      .testimonials .buttons button:before,
      .testimonials .buttons a:before {
        background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), -webkit-radial-gradient(#0071a7, #003d5b);
        background-image: linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), radial-gradient(#0071a7, #003d5b);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -100;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .testimonials .buttons .modal-trigger:hover:before,
      .testimonials .buttons button:hover:before,
      .testimonials .buttons a:hover:before {
        opacity: 1; }
      .testimonials .buttons .modal-trigger:hover,
      .testimonials .buttons button:hover,
      .testimonials .buttons a:hover {
        font-weight: 400;
        color: #e6e6e6; }
  .testimonials .testimonial-list .testimonial-text {
    padding: 20px; }
    .testimonials .testimonial-list .testimonial-text::after {
      clear: both;
      content: "";
      display: table; }
    .testimonials .testimonial-list .testimonial-text:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.05); }
  .testimonials h2 {
    color: #050607;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    text-shadow: 0 0 5px #0094da; }
    @media screen and (min-width: 53.75em) {
      .testimonials h2 {
        font-size: 2.4em; } }
  .testimonials .cycle-slide {
    width: 100%;
    height: auto; }
  .testimonials .testimonial-wrap {
    margin-bottom: 10px; }
    .testimonials .testimonial-wrap::after {
      clear: both;
      content: "";
      display: table; }
    .testimonials .testimonial-wrap .testimonial-text {
      height: auto;
      width: 100%; }
    @media screen and (min-width: 40em) {
      .testimonials .testimonial-wrap p {
        padding: 5px 80px;
        line-height: 1.2; } }

.colourbg {
  background-color: #e3e3e3;
  border-top: solid 1px #0094da; }

.openhours {
  max-width: 450px;
  margin: 0 auto 10px; }
  .openhours p {
    padding: 5px;
    margin: 0; }
  .openhours .openrow:nth-child(odd) {
    background-color: #d6d6d6; }
  .openhours .day {
    display: inline-block;
    width: 40%; }
  .openhours .time {
    display: inline-block;
    width: 55%; }
  .openhours .callout {
    text-align: center; }
    .openhours .callout p {
      color: #0094da;
      text-shadow: 1px 1px 1px #000;
      margin: 0;
      line-height: 1; }
      .openhours .callout p strong {
        font-weight: 900;
        font-size: 1.1em; }

.showroom .pagination::after {
  clear: both;
  content: "";
  display: table; }

.showroom .pagination a {
  color: #a3a3a3;
  font-weight: 700; }
  .showroom .pagination a:hover {
    color: #8a8a8a; }

.showroom .pagination div {
  display: inline-block;
  padding: 0 2px;
  vertical-align: middle;
  line-height: 1.1; }

.showroom .pagination span {
  display: block; }

@media screen and (min-width: 40em) {
  .showroom .pagination span {
    display: inline-block; } }

.showroom .pagination,
.showroom .jumpperpage,
.showroom .jump-order,
.showroom .jumpgoto {
  text-align: center; }
  @media screen and (min-width: 53.75em) {
    .showroom .pagination,
    .showroom .jumpperpage,
    .showroom .jump-order,
    .showroom .jumpgoto {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%; }
      .showroom .pagination:last-child,
      .showroom .jumpperpage:last-child,
      .showroom .jump-order:last-child,
      .showroom .jumpgoto:last-child {
        margin-right: 0; }
      .showroom .pagination:nth-child(2n),
      .showroom .jumpperpage:nth-child(2n),
      .showroom .jump-order:nth-child(2n),
      .showroom .jumpgoto:nth-child(2n) {
        margin-right: 0; }
      .showroom .pagination:nth-child(2n+1),
      .showroom .jumpperpage:nth-child(2n+1),
      .showroom .jump-order:nth-child(2n+1),
      .showroom .jumpgoto:nth-child(2n+1) {
        clear: left; } }
  .showroom .pagination label,
  .showroom .pagination select,
  .showroom .jumpperpage label,
  .showroom .jumpperpage select,
  .showroom .jump-order label,
  .showroom .jump-order select,
  .showroom .jumpgoto label,
  .showroom .jumpgoto select {
    display: inline-block;
    margin: 3px 0; }

.showroom .bottom-pagination .pagination {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%; }
  .showroom .bottom-pagination .pagination:last-child {
    margin-right: 0; }
  .showroom .bottom-pagination .pagination:nth-child(1) {
    margin-right: 0; }

.showroom .item {
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: black 2px 2px 7px; }

.showroom .itembody {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-color: #e1e1e1;
  border-width: 3px;
  border-style: solid;
  border-top: none;
  padding: 5px; }
  .showroom .itembody::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (min-width: 53.75em) {
    .showroom .itembody p {
      font-size: 1.2em; } }

@media screen and (min-width: 40em) {
  .showroom .piccont,
  .showroom .specs {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%; }
    .showroom .piccont:last-child,
    .showroom .specs:last-child {
      margin-right: 0; }
    .showroom .piccont:nth-child(2n),
    .showroom .specs:nth-child(2n) {
      margin-right: 0; }
    .showroom .piccont:nth-child(2n+1),
    .showroom .specs:nth-child(2n+1) {
      clear: left; } }

.showroom .itemhead {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 100%, transparent 2%), -webkit-radial-gradient(#e1e1e1, #c8c8c8);
  background-image: linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 100%, transparent 2%), radial-gradient(#e1e1e1, #c8c8c8);
  background-color: #eeeeee; }
  .showroom .itemhead a:hover {
    color: #74d2ff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); }
  .showroom .itemhead h2 {
    margin: 0;
    padding: 5px;
    line-height: 1; }

.showroom .itemname {
  text-align: center; }

.showroom .itemprice {
  text-align: center; }

.showroom .piccont {
  text-align: center; }

.showroom .mainpic {
  width: 50%;
  position: relative;
  margin: auto; }
  .showroom .mainpic .mainimg {
    width: 100%; }

.showroom .specialoffer img {
  position: absolute;
  border-radius: 10px 0 0 0;
  border: none;
  box-shadow: none;
  top: 0;
  left: 0;
  width: 35%; }

.showroom .thumbnails img {
  display: inline-block;
  height: 70px;
  margin: 3px; }

.showroom .specscol {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 48.821174201%; }
  .showroom .specscol:last-child {
    margin-right: 0; }
  .showroom .specscol:nth-child(2n) {
    margin-right: 0; }
  .showroom .specscol:nth-child(2n+1) {
    clear: left; }

.video {
  margin: 10px auto; }
  @media screen and (min-width: 53.75em) {
    .video {
      float: right;
      margin: 10px 0 10px 10px;
      max-width: 45%; } }
  .video .video-border {
    border-radius: 2px;
    box-shadow: black 2px 2px 7px; }
    .video .video-border::after {
      clear: both;
      content: "";
      display: table; }
  .video .video-wrapper {
    border-radius: 2px;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative; }
    .video .video-wrapper embed,
    .video .video-wrapper object,
    .video .video-wrapper iframe {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%; }

.contact .contact-col h3 {
  margin: 0; }
  .contact .contact-col h3.telnum {
    margin: 10px 0; }
  .contact .contact-col h3.email a {
    text-shadow: 1px 1px 2px rgba(189, 189, 189, 0.7); }

@media screen and (min-width: 53.75em) {
  .contact .contact-col {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%; }
    .contact .contact-col:last-child {
      margin-right: 0; }
    .contact .contact-col:nth-child(2n) {
      margin-right: 0; }
    .contact .contact-col:nth-child(2n+1) {
      clear: left; } }

.contact .contactform .contact-form {
  font-size: 1.2em;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .contact .contactform .contact-form .formgroup {
    display: block; }
    .contact .contactform .contact-form .formgroup label {
      text-shadow: 1px 1px 1px rgba(112, 112, 112, 0.7); }
    @media screen and (min-width: 53.75em) {
      .contact .contactform .contact-form .formgroup label {
        display: inline-block;
        text-align: right;
        width: 25%; } }
  @media screen and (min-width: 53.75em) and (min-width: 75em) {
    .contact .contactform .contact-form .formgroup label {
      font-size: 1.2em; } }
    @media screen and (min-width: 53.75em) {
      .contact .contactform .contact-form .formgroup .messagelabel {
        vertical-align: top;
        padding-top: 1em; }
      .contact .contactform .contact-form .formgroup #name, .contact .contactform .contact-form .formgroup #email, .contact .contactform .contact-form .formgroup #phonenum, .contact .contactform .contact-form .formgroup #company, .contact .contactform .contact-form .formgroup #subject, .contact .contactform .contact-form .formgroup #message {
        display: inline-block;
        width: 70%; } }
  .contact .contactform .contact-form .disp-error {
    color: red;
    text-shadow: 1px 1px 1px #000; }
    @media screen and (min-width: 53.75em) {
      .contact .contactform .contact-form .disp-error {
        margin-left: 30%; } }
  .contact .contactform .contact-form .buttons {
    text-align: right; }
    .contact .contactform .contact-form .buttons .submit-btn {
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), -webkit-radial-gradient(#0083c1, #004f74);
      background-image: linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), radial-gradient(#0083c1, #004f74);
      background-size: 100%;
      position: relative;
      z-index: 100;
      cursor: pointer;
      border: solid 3px #0094da;
      border-radius: 5px;
      color: white;
      background-color: #0094da;
      font-weight: 400;
      margin: 5px 0;
      padding: 10px 25px;
      display: inline-block;
      line-height: 1;
      text-shadow: 1px 1px 2px rgba(189, 189, 189, 0.7);
      box-shadow: black 2px 2px 5px; }
      .contact .contactform .contact-form .buttons .submit-btn:before {
        background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), -webkit-radial-gradient(#0071a7, #003d5b);
        background-image: linear-gradient(rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.1) 50%, transparent 50%), radial-gradient(#0071a7, #003d5b);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -100;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .contact .contactform .contact-form .buttons .submit-btn:hover:before {
        opacity: 1; }
      .contact .contactform .contact-form .buttons .submit-btn:hover {
        font-weight: 400;
        color: #e6e6e6; }
      @media screen and (min-width: 53.75em) {
        .contact .contactform .contact-form .buttons .submit-btn {
          margin-right: 20%; } }

.maps #map_canvas {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  border-top: solid 1px #0094da;
  width: 100%;
  height: 400px; }

.maps #map_canvas img {
  max-width: none;
  border-radius: 0;
  box-shadow: none; }

.maps #map_canvas .content {
  padding: .7em .3em 1em .3em; }

.maps #map_canvas .firstHeading {
  font-size: 1.8em;
  margin-bottom: 0; }

.maps #map_canvas .bodyContent p {
  font-size: 1em;
  line-height: 1.3;
  margin-bottom: 0; }

.maps #map_canvas .bodyContent p:last-of-type {
  border-top: 1px solid silver;
  margin-top: .5em;
  padding-top: .3em; }

footer {
  background-color: #a3a3a3;
  background-image: url("/images/bg-glass.png");
  background-position: center;
  background-size: cover;
  border-top: solid 1px #0094da; }
  @media screen and (min-width: 40em) {
    footer {
      padding-top: 5px; } }
  footer .footer-wrap {
    max-width: 1400px;
    margin: auto; }
  footer .footer {
    color: #050607;
    text-align: center;
    padding: 0 10px;
    text-shadow: 1px 1px 1px #000;
    margin: 10px 10px 0; }
    footer .footer::after {
      clear: both;
      content: "";
      display: table; }
    @media screen and (min-width: 53.75em) {
      footer .footer {
        margin: 30px 10px 0; } }
    footer .footer .footer-links {
      min-height: 80px; }
    footer .footer li span {
      display: none; }
    @media screen and (min-width: 53.75em) {
      footer .footer li {
        display: inline; }
        footer .footer li:first-child {
          margin-left: -10px; }
        footer .footer li span {
          display: inline-block; } }
    footer .footer li a {
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      color: #050607;
      font-weight: 400;
      text-shadow: 1px 1px 1px #000;
      display: block;
      font-size: .9em;
      padding-top: 10px; }
      footer .footer li a:hover {
        color: #0094da; }
      @media screen and (min-width: 53.75em) {
        footer .footer li a {
          display: inline-block;
          font-size: 1em;
          padding: 0;
          width: 19%; } }
  footer .credwrap {
    margin: 10px 0; }
    @media screen and (min-width: 53.75em) {
      footer .credwrap {
        margin: 0; } }
  footer .copy,
  footer .credit {
    color: #ffffff;
    font-size: 0.8em;
    text-align: center; }
    footer .copy p,
    footer .credit p {
      margin: 0; }
    footer .copy a,
    footer .credit a {
      color: #0094da;
      display: inline-block; }
      footer .copy a:hover,
      footer .credit a:hover {
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        color: #ffffff; }
    @media screen and (min-width: 53.75em) {
      footer .copy,
      footer .credit {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        footer .copy:last-child,
        footer .credit:last-child {
          margin-right: 0; }
        footer .copy p,
        footer .credit p {
          margin-bottom: 30px; } }
  @media screen and (min-width: 53.75em) {
    footer .copy {
      text-align: left; } }
  @media screen and (min-width: 53.75em) {
    footer .credit {
      text-align: right; } }

.wrapper::after {
  clear: both;
  content: "";
  display: table; }

body {
  background-color: white; }
  body .main {
    min-height: 100vh;
    overflow: hidden; }
    body .main::after {
      clear: both;
      content: "";
      display: table; }
  body .holding h2 {
    text-align: center;
    margin: 30px 0; }

.tags {
  text-align: center;
  display: none; }
